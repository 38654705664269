// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_k4";
export var caseStudyBackground__lineColor = "bl_kW";
export var caseStudyHead = "bl_k6";
export var caseStudyHead__imageWrapper = "bl_kT";
export var caseStudyProjectsSection = "bl_k5";
export var caseStudyQuote__bgLight = "bl_k2";
export var caseStudyQuote__bgRing = "bl_k1";
export var caseStudyVideo__ring = "bl_k8";
export var caseStudy__bgDark = "bl_kS";
export var caseStudy__bgLight = "bl_kR";